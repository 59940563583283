import React, { FC } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { transparentize } from 'polished';
import Link from 'gatsby-link';
import { WindowLocation } from '@gatsbyjs/reach-router';
import { breakpointUp } from '../../../styles/mediaUtils';
import defaultTheme, { breakpointWidths } from '../../../styles/themeVariables';
import NavMenuItems from './NavMenuItems';
import { Layout } from '../../../new/layout/Layout';
import { Grid } from '../../../new/layout/Grid';
import { typeScale } from '../../../styles/newTypography';
import NewMobileMenu, { menuItems } from './NewMobileMenu';
import { spacing } from '../../../styles/newThemeVariables.styles';

const StyledNav = styled.nav<{
  menuOpen?: boolean;
  scrolledUp?: boolean;
  top?: boolean;
}>`
  position: fixed;
  width: 100%;
  color: ${({ menuOpen, theme }) => (menuOpen ? theme.black : theme.fg)};
  top: 0;
  left: 0;
  height: ${({ theme }) => theme.navHeight};
  z-index: 600;
  background-color: ${({ theme }) => theme.bgPrimary};

  /* Transitions */
  transition: all 500ms ease-in-out;
  transition-property: transform, background-color, height;

  /* Transitions */
  ${({ scrolledUp, top }) => {
    // delay some nav style changes until after it has been hidden:
    const delay = !(scrolledUp || top) && '500ms';
    return css`
      transition: height 500ms ease-in-out, transform 1000ms ease-in-out,
        background-color 500ms ease-in-out ${delay},
        border-bottom-color 500ms ease-in-out ${delay};
    `;
  }}

  /* Hide the nav when scrolling down: */

  /* Move Nav further up (220%) behind safari's semi-transparent bar to fix scrolling glitches */
  transform: translateY(${({ top, scrolledUp }) =>
    top || scrolledUp ? '0%' : '-220%'});

  /* Sticky styles: */
  border-bottom: 0.5px solid ${({ theme }) => transparentize(0.5, theme.greyFg)};
  ${({ top, theme }) =>
    top &&
    css`
      /* Top of screen styles: */
      background-color: transparent;
      border-bottom-color: transparent;

      @media ${breakpointUp.md} {
        height: ${theme.navHeightLg};
      }
    `};

  @media ${breakpointUp.sm} {
    height: ${({ theme }) => theme.navHeightMd};
  }

  @media ${breakpointUp.md} {
    height: ${({ theme }) => theme.navHeightLg};
  }
`;

const StyledContentWrapper = styled.div<{ top?: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  grid-column: 1 / -1;

  @media ${breakpointUp.md} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: ${spacing.space24};
  }

  /* Use 'position' the centralise the content so it can be animated smoothly: */
  bottom: calc((${spacing.space18} + ${spacing.space2}));

  /* Transitions */
  transition: all 350ms ease-in-out;
  transition-property: bottom, padding-bottom;
  ${({ top }) =>
    top &&
    /* Top of screen styles: */
    css`
      /* @media ${breakpointUp.lg} {
        padding-bottom: 0.75rem;
        bottom: 0;
      } */
    `};

  @media ${breakpointUp.sm} {
    bottom: calc(50% - 1rem);
  }

  @media ${breakpointUp.md} {
    bottom: ${spacing.space36};
  }
`;

const StyledActiveLink = styled.span`
  display: inline;
  font-size: 19px;
  line-height: 29.45px;
  color: ${({ theme }) => theme.text.text400};

  @media ${breakpointUp.sm} {
    font-size: 17px;
    line-height: 25.5px;
  }

  @media ${breakpointUp.md} {
    display: none;
  }
`;

const StyledDescription = styled.span`
  display: none;

  @media ${breakpointUp.desktop} {
    display: inline;
    color: ${({ theme }) => theme.text400};
    ${typeScale.desktop.body.sm};

    &:hover {
      color: ${({ theme }) => theme.textPrimary};
    }
  }
`;

const StyledLogoLink = styled(Link)`
  position: relative;
  display: flex;
  gap: 8px;
  align-items: baseline;

  div {
    ${typeScale.mobile.body.md};
    display: inline-block;
    margin-bottom: 0;
  }

  svg {
    position: absolute;
    left: -1px;
    top: 9px;
  }

  @media ${breakpointUp.sm} {
    line-height: 25.5px;

    div {
      ${typeScale.desktop.body.sm};
    }
  }

  @media (width < ${breakpointWidths.lg}) {
    grid-column: span 5;
  }

  @media (width >= ${breakpointWidths.lg}) {
    grid-column: span 6;
  }
`;

const menuTransitionDuration = 150;

const TransitionMenu = styled(NewMobileMenu)`
  transition: opacity ${menuTransitionDuration}ms ease-out;
  background-color: ${({ theme }) => theme.bgPrimary};

  &.menu-transition-appear,
  &.menu-transition-enter {
    opacity: 0;
  }

  &.menu-transition-appear-active,
  &.menu-transition-enter-active {
    opacity: 1;
  }

  &.menu-transition-exit {
    opacity: 1;
  }

  &.menu-transition-exit-active {
    opacity: 0;
  }
`;

const StyledLayout = styled(Layout)`
  height: 100%;
`;

const NavPlaceholder = styled.div`
  height: ${({ theme }) => theme.navHeight};
  background-color: ${({ theme }) => theme.bgPrimary};

  @media ${breakpointUp.sm} {
    height: ${({ theme }) => theme.navHeightMd};
  }

  @media ${breakpointUp.md} {
    height: ${({ theme }) => theme.navHeightLg};
  }
`;
export interface NavComponentProps {
  menuOpen: boolean;
  toggleMenu: () => void;
  scrolledUp: boolean;
  top: boolean;
  location: WindowLocation;
}

const NavComponent: FC<NavComponentProps> = ({
  toggleMenu,
  menuOpen,
  scrolledUp,
  top,
  location,
}: NavComponentProps) => {
  const currentPath = location.pathname;
  const activeMenuItem = menuItems.find((item) =>
    currentPath.includes(item.path)
  )?.label;

  return (
    <>
      <StyledNav menuOpen={menuOpen} scrolledUp={scrolledUp} top={top}>
        <StyledLayout>
          <Grid>
            <StyledContentWrapper top={top}>
              <StyledLogoLink
                to="/"
                onClick={menuOpen ? toggleMenu : undefined}
              >
                <div>Elsewhen</div>

                <StyledActiveLink>{activeMenuItem}</StyledActiveLink>

                {!menuOpen && (
                  <StyledDescription>
                    Digital Product Consultancy
                  </StyledDescription>
                )}
              </StyledLogoLink>

              {/* Desktop menu items */}
              <NavMenuItems
                top={top}
                location={location}
                scrolledUp={scrolledUp}
              />

              {/* Mobile menu: */}
              <ThemeProvider theme={defaultTheme}>
                <TransitionMenu
                  menuOpen={menuOpen}
                  location={location}
                  onOpenChange={toggleMenu}
                />
              </ThemeProvider>
            </StyledContentWrapper>
          </Grid>
        </StyledLayout>
      </StyledNav>
      <NavPlaceholder />
    </>
  );
};

export default NavComponent;
