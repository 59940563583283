export const greyScale = {
  black25: 'rgba(22, 19, 20, 0.25)',
  black15: 'rgba(22, 19, 20, 0.15)',
  black5: 'rgba(22, 19, 20, 0.05)',
  white25: 'rgba(240, 240, 240, 0.25)',
  white15: 'rgba(240, 240, 240, 0.15)',
  white5: 'rgba(240, 240, 240, 0.05)',
  grey000: '#FAFAFA',
  grey100: '#F0F0F0',
  grey200: '#D0CFCF',
  grey300: '#A7A7A7',
  grey400: '#807D7D',
  grey500: '#4A4848',
  grey600: '#262525',
  grey700: '#161314',
  grey700_100: '#0D0B0B',
};

export const border = {
  border000: greyScale.black5,
  border100: greyScale.black15,
  border200: greyScale.black25,
  border300: greyScale.grey300,
  border400: greyScale.grey400,
  border500: greyScale.grey500,
  border600: greyScale.grey600,
  border700: greyScale.grey700,
  focus: '#1573FC',
};

export const fill = {
  fill000: greyScale.grey700_100,
  fill100: greyScale.black25,
  fill200: greyScale.black15,
  fill300: greyScale.black5,
};

export const spacing = {
  space0: '0rem',
  space2: '0.125rem',
  space4: '0.25rem',
  space8: '0.5rem',
  space10: '0.625rem',
  space12: '0.75rem',
  space16: '1rem',
  space18: '1.125rem',
  space24: '1.5rem',
  space32: '2rem',
  space36: '2.25rem',
  space40: '2.5rem',
  space48: '3rem',
  space56: '3.5rem',
  space60: '3.75',
  space64: '4rem',
  space72: '4.5rem',
  space80: '5rem',
  space88: '5.5rem',
  space96: '6rem',
  space104: '6.5rem',
  space112: '7rem',
  space120: '7.5rem',
  space128: '8rem',
  space136: '8.5rem',
  space144: '9rem',
  space152: '9.5rem',
  space160: '10rem',
  space168: '10.5rem',
  space176: '11rem',
  space184: '11.5rem',
  space192: '12rem',
  space200: '12.5rem',
  space250: '15.625rem',
  space264: '16.5rem',
  space294: '18.375rem',
  space342: '21.375rem',
  space378: '23.625rem',
};
