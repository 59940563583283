import styled, { css } from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';

export const GridStyles = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${({ theme }) => theme.space16};
  grid-auto-columns: 0;

  @media (${breakpointUp.tablet}) {
    grid-template-columns: repeat(12, 1fr);
  }

  @media (${breakpointUp.desktop}) {
    grid-column-gap: ${({ theme }) => theme.space24};
  }
`;

export const Grid = styled.div`
  ${GridStyles}
`;
