/**
 * Some routes have a _MATCH, which is the final string format of the route, useful for inserting
 * params to match against in Express/ReactRouter (e.g. ':id').
 *
 * All routes have a _ROUTE used for normal link creation, which ensures you're using the expected
 * types for extra safety.
 */

export const HOME_PAGE_ROUTE = '';
export const HOME_PAGE_ROUTE2 = '/';

export const AI_PAGE_ROUTE = '/generative-ai/';
export const WORK_PAGE_ROUTE = '/work/';
export const SERVICES_PAGE_ROUTE = '/services/';
export const ABOUT_PAGE_ROUTE = '/about/';
export const REPORTS_PAGE_ROUTE = '/reports/';
export const CONTACT_PAGE_ROUTE = '/contact/';
export const COOKIE_POLICY_ROUTE = '/cookie-policy/';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy/';
export const BLOG_PAGE_ROUTE = '/blog/';
export const AUTHOR_POST_PAGE_ROUTE = (slug: string): string =>
  `/blog-author/${slug}/`;
export const AUTHOR_POST_PAGE_MATCH = (pathname: string): boolean =>
  /^\/old\/blog-author\/.+/.test(pathname);
export const BLOG_POST_PAGE_ROUTE = (slug: string): string => `/blog/${slug}/`;
export const BLOG_POST_PAGE_MATCH = (pathname: string): boolean =>
  /^\/old\/blog\/.+/.test(pathname);
export const VIDEO_PAGE_ROUTE = '/video/';
export const VIDEO_POST_PAGE_ROUTE = (slug: string): string =>
  `/video/${slug}/`;
export const VIDEO_POST_PAGE_MATCH = (pathname: string): boolean =>
  /^\/old\/video\/.+/.test(pathname);

export const BUPA_CASESTUDY_ROUTE = '/work/bupa-dental/';
export const BAROMETER_CASESTUDY_ROUTE = '/work/google-barometer/';
export const INMARSAT_CASESTUDY_ROUTE = '/work/inmarsat-design-system/';
export const HOPSTER_CASESTUDY_ROUTE = '/work/hopster/';
export const KINDEO_CASESTUDY_ROUTE = '/work/kindeo/';
export const SUZY_SNOOZE_CASESTUDY_ROUTE = '/work/suzy-snooze/';
export const JAMESON_CASESTUDY_ROUTE = '/work/jameson/';
export const FRAME_CASESTUDY_ROUTE = '/work/gleeds/';
export const QUIT_GENIUS_CASESTUDY_ROUTE = '/work/quit-genius/';
export const GO_COMPARE_CASESTUDY_ROUTE = '/work/gocompare/';
export const MYPENSION_CASESTUDY_ROUTE = '/work/my-pension/';
export const GOOGLE_RETAIL_CASESTUDY_ROUTE = '/work/google-retail/';
export const ZEGO_CASESTUDY_ROUTE = '/work/zego/';
export const FOUNDERS_CASESTUDY_ROUTE = '/work/founders-bank/';

export const CRYPTO_BANKING_ROUTE = '/reports/crypto-banking/';
export const BUILD_A_BANK_ROUTE = '/reports/how-to-build-a-bank/';
export const EMBEDDED_FINANCE_ROUTE = '/reports/embedded-finance/';
export const BUSINESS_IMPACT_DESIGN_ROUTE = '/reports/business-impact-design/';
export const NARROWING_THE_GAP_ROUTE = '/reports/narrowing-the-gap/';

export const API_BLOG_CATEGORY_MATCH = (
  category: string,
  page: string
): string => `/api/blog-category/${category}/${page}`;
export const API_BLOG_CATEGORY_ROUTE = (
  category: 'all' | 'tiles' | number,
  page: number
): string => API_BLOG_CATEGORY_MATCH(category.toString(), page.toString());
export const API_BLOG_POST_ROUTE = (slug: string): string =>
  `/api/blog-post/${slug}`;
export const API_BLOG_RELATED_MATCH = (ids: string): string =>
  `/api/blog-related/${ids}`;
export const API_BLOG_RELATED_ROUTE = (ids: number[]): string =>
  `/api/blog-related/${ids.join(',')}`;
export const API_CONTACT_ROUTE = '/api/contact';
export const API_NEWSLETTER_ROUTE = '/api/newsletter';
export const API_WHITE_PAPER_DOWNLOAD_ROUTE = (template: string): string =>
  `/api/white-paper-download/${template}`;
