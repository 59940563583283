import styled, { keyframes } from 'styled-components';
import React from 'react';
import Link from 'gatsby-link';
import { WindowLocation } from '@gatsbyjs/reach-router';
import { breakpointUp } from '../../../styles/mediaUtils';
import {
  AI_PAGE_ROUTE,
  ABOUT_PAGE_ROUTE,
  BLOG_PAGE_ROUTE,
  CONTACT_PAGE_ROUTE,
  REPORTS_PAGE_ROUTE,
  SERVICES_PAGE_ROUTE,
  WORK_PAGE_ROUTE,
} from '../../../routes';
import { OutlineRoutes } from './OutlineColors';
import CallToActionBtn from '../CallToActionLink';
import { typeScale } from '../../../styles/newTypography';
import { breakpointWidths } from '../../../styles/themeVariables';

const StyledNavMenu = styled.ul`
  display: none;
  margin: 0;
  padding: 0;

  @media ${breakpointUp.md} {
    display: flex;
    justify-content: space-between;
    flex: 1;
    position: relative;
    bottom: -0.375rem;
  }

  @media ${breakpointUp.lg} {
    flex: 1 0;
  }

  @media (width < ${breakpointWidths.lg}) {
    grid-column: span 7;
  }

  @media (width >= ${breakpointWidths.lg}) {
    grid-column: span 6;
  }
`;

const StyledDropDownWrapper = styled.li<{
  outlined?: boolean;
  outlineColor?: OutlineRoutes;
}>`
  display: flex; /* Ensure dropdown aligns */
  justify-content: space-evenly;
  flex-flow: column;

  a {
    display: block;
    position: relative;
    ${typeScale.desktop.body.sm};
    color: ${({ theme }) => theme.textPrimary};
    padding: ${({ theme }) => theme.spacing.space4} 0;
  }
`;

const extendKeyframes = keyframes`
  from {
    width: 30%;
    opacity: 0.1;
  }

  to {
    width: 100%;
    opacity: 1;
  }
`;

const StyledLinkWrapper = styled(StyledDropDownWrapper)<{
  top?: boolean | string;
  outlineColor?: OutlineRoutes;
}>`
  a {
    &:hover::after {
      content: '';
      border-bottom: 0.09375rem solid ${({ theme }) => theme.border.Border200};
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0;
      width: 100%;
      opacity: 1;
      animation: ${extendKeyframes} 200ms ease-in-out;
    }

    &.active::after,
    &.active:hover::after {
      content: '';
      border-bottom: 0.09375rem solid ${({ theme }) => theme.textPrimary};
      position: absolute;
      bottom: 0;
      transition: bottom 500ms ease-in-out;
      left: 0;
      height: 0;
      width: 100%;
      animation: none;
    }
  }
`;

const StyledButtonLinkWrapper = styled(StyledLinkWrapper)`
  a {
    &:hover::after {
      content: none;
    }
    /* stylelint-disable */
    &.active::after,
    &.active:hover::after {
      content: none;
    }
    /* stylelint-enable */
  }
`;

type State = { subject: null | string; listOpen: boolean };

export type Option = {
  value: string | number;
  label: string;
};

type Props = {
  top: boolean | string;
  location: WindowLocation;
  scrolledUp: boolean;
};

export default class NavMenuItems extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { subject: null, listOpen: false };
  }

  //  if dropdown's open & the user scrolls down, close it
  componentDidUpdate(
    prevProps: Record<string, unknown>,
    prevState: Record<string, unknown>
  ): void {
    const { scrolledUp } = this.props;

    if (prevState.listOpen && prevProps.scrolledUp !== scrolledUp) {
      this.onScrollUp();
    }
  }

  onScrollUp = (): void => {
    this.setState({ listOpen: false });
  };

  render(): React.ReactNode {
    const { top, location } = this.props;

    const outlineColor = location.pathname as OutlineRoutes;

    return (
      <StyledNavMenu>
        <StyledLinkWrapper top={top} outlineColor={outlineColor}>
          <Link
            activeClassName="active"
            to={AI_PAGE_ROUTE}
            partiallyActive
            onClick={() => {
              this.setState({ listOpen: false });
            }}
          >
            Generative AI
          </Link>
        </StyledLinkWrapper>
        <StyledLinkWrapper top={top} outlineColor={outlineColor}>
          <Link
            activeClassName="active"
            to={WORK_PAGE_ROUTE}
            partiallyActive
            onClick={() => {
              this.setState({ listOpen: false });
            }}
          >
            Case Studies
          </Link>
        </StyledLinkWrapper>
        <StyledLinkWrapper top={top} outlineColor={outlineColor}>
          <Link
            activeClassName="active"
            to={SERVICES_PAGE_ROUTE}
            partiallyActive
            onClick={() => {
              this.setState({ listOpen: false });
            }}
          >
            Services
          </Link>
        </StyledLinkWrapper>
        <StyledLinkWrapper top={top} outlineColor={outlineColor}>
          <Link
            activeClassName="active"
            to={ABOUT_PAGE_ROUTE}
            partiallyActive
            onClick={() => {
              this.setState({ listOpen: false });
            }}
          >
            About
          </Link>
        </StyledLinkWrapper>
        <StyledLinkWrapper top={top} outlineColor={outlineColor}>
          <Link
            activeClassName="active"
            to={BLOG_PAGE_ROUTE}
            partiallyActive
            onClick={() => {
              this.setState({ listOpen: false });
            }}
          >
            Blog
          </Link>
        </StyledLinkWrapper>
        <StyledLinkWrapper top={top} outlineColor={outlineColor}>
          <Link
            activeClassName="active"
            to={REPORTS_PAGE_ROUTE}
            partiallyActive
            onClick={() => {
              this.setState({ listOpen: false });
            }}
          >
            Reports
          </Link>
        </StyledLinkWrapper>
        <StyledButtonLinkWrapper top={top}>
          <CallToActionBtn
            to={CONTACT_PAGE_ROUTE}
            size="md"
            isSelected={location?.pathname === CONTACT_PAGE_ROUTE}
            onClick={() => {
              this.setState({ listOpen: false });
            }}
          >
            Contact
          </CallToActionBtn>
        </StyledButtonLinkWrapper>
      </StyledNavMenu>
    );
  }
}
