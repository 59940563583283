import { DefaultTheme, ThemeProvider } from 'styled-components';
import 'sanitize.css/sanitize.css';
import React, { FC, useEffect, useRef } from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import ReactGA from 'react-ga';
import lightTheme from '../../styles/themeVariables';
import styled from 'styled-components';
import GlobalStyles from '../../styles/globalStyles';
import '../../fonts.css';

// Read more about ErrorBoundaries here:
// https://reactjs.org/docs/error-boundaries.html
import ErrorBoundary from './ErrorBoundary';
import RoutesTransitionGroup from './RoutesTransitionGroup';
import FooterWrap from '../../util/getFooterData';
import Nav from '../../new/components/Nav';

const pageConfig = graphql`
  query PageWrap {
    site {
      siteMetadata {
        version
        env
      }
    }
    twitterImg: file(relativePath: { eq: "twitter_card.png" }) {
      childImageSharp {
        gatsbyImageData(
          formats: [PNG]
          width: 4000
          layout: CONSTRAINED
          quality: 79
          webpOptions: { quality: 79 }
          breakpoints: [4000]
        )
      }
    }
  }
`;

interface Props {
  location: PageProps['location'];
  theme?: DefaultTheme | ((theme: DefaultTheme) => DefaultTheme);
}

const ContentContainer = styled.div`
  min-height: 100vh;
`;

const PageWrap: FC<Props> = ({ location, theme, children }) => {
  const data: GatsbyTypes.PageWrapQuery = useStaticQuery(pageConfig);

  const initialLocation = useRef(location);

  useEffect(() => {
    console.log('Website Version', data.site?.siteMetadata?.version);

    // Initialize Google Analytics tracker
    ReactGA.initialize('UA-21697155-1', {
      testMode: data.site?.siteMetadata?.env !== 'production',
    });
    ReactGA.pageview(initialLocation.current?.pathname || '');
  }, [data]);

  return (
    <ErrorBoundary>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />
        <main role="main">
          <ThemeProvider theme={theme || lightTheme}>
            <ContentContainer>
              <Nav location={location} />
              <RoutesTransitionGroup location={location}>
                {children}
              </RoutesTransitionGroup>
              <FooterWrap />
            </ContentContainer>
          </ThemeProvider>
        </main>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default PageWrap;
